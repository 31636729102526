// Material Dashboard 2 PRO React templates / examples
import OrderTable from "layouts/templates/Order-table";

// Data
import dataTableData from "layouts/pages/projects/all-project-users/data/dataTableData";

function AllProjectUsers() {
	return (
		<OrderTable
			title="Пользователи на проекте"
			description="Проект:"
			leftButtonName="Новый"
			rightButtonName="Справочник"
			filterOptions={["Редактор", "Оунер", "Чтение"]}
			dataTableData={dataTableData}
		/>
	);
}

export default AllProjectUsers;

/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// uuid is a library for generating unique id
import { v4 as uuidv4 } from "uuid";

// Kanban application components
import Card from "layouts/applications/kanban/components/Card";

// Images
import officeDark from "assets/images/office-dark.jpg";
import meeting from "assets/images/meeting.jpg";
import homeDecore from "assets/images/home-decor-1.jpg";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import team5 from "assets/images/team-5.jpg";

const boards = {
	columns: [
		{
			id: uuidv4(),
			title: "Задачи",
			cards: [
				{
					id: uuidv4(),
					template: "Проверка объекта",
				},
				{
					id: uuidv4(),
					template: "Уборка строительного мусора",
				},
				{
					id: uuidv4(),
					template: "Чистовая отделка",
				},
				{
					id: uuidv4(),
					template: (
						<Card
							image={officeDark}
							badge={{ color: "dark", label: "Создан" }}
							content="Сделать фотографию объекта"
							attachedFiles={3}
							members={[team1, team2, team3]}
						/>
					),
				},
			],
		},
		{
			id: uuidv4(),
			title: "На проверке",
			cards: [
				{
					id: uuidv4(),
					template: (
						<Card
							badge={{ color: "error", label: "Отменен" }}
							content="Доставка материалов"
							attachedFiles={9}
							members={[team2, team3]}
						/>
					),
				},
				{
					id: uuidv4(),
					template: (
						<Card
							badge={{ color: "info", label: "В работе" }}
							content="Заливка стяжки"
							attachedFiles={3}
							members={[team5, team4]}
						/>
					),
				},
				{
					id: uuidv4(),
					template: (
						<Card
							image={meeting}
							badge={{ color: "info", label: "В работе" }}
							content="Проектные работы"
							attachedFiles={3}
							members={[team1, team2, team3]}
						/>
					),
				},
			],
		},
		{
			id: uuidv4(),
			title: "Выполненные задачи",
			cards: [
				{
					id: uuidv4(),
					template: (
						<Card
							badge={{ color: "success", label: "Готово" }}
							content="Переговоры с заказчиком"
							attachedFiles={11}
							members={[team3, team2]}
						/>
					),
				},
				{
					id: uuidv4(),
					template: (
						<Card
							badge={{ color: "success", label: "Готово" }}
							content="Подготовительные работы"
							progress={80}
							members={[team3]}
						/>
					),
				},
				{
					id: uuidv4(),
					template: (
						<Card
							badge={{ color: "success", label: "Готово" }}
							content="Черновая отделка"
							progress={60}
							attachedFiles={6}
							members={[team5, team1]}
						/>
					),
				},
			],
		},
		// {
		// 	id: uuidv4(),
		// 	title: "Заявки",
		// 	cards: [
		// 		{
		// 			id: uuidv4(),
		// 			template: (
		// 				<Card
		// 					image={homeDecore}
		// 					badge={{ color: "success", label: "done" }}
		// 					content="Redesign for the home page"
		// 					attachedFiles={8}
		// 					members={[team5, team1, team4]}
		// 				/>
		// 			),
		// 		},
		// 		{
		// 			id: uuidv4(),
		// 			template: (
		// 				<Card
		// 					badge={{ color: "success", label: "done" }}
		// 					content="Schedule winter campaign"
		// 					attachedFiles={2}
		// 					members={[team1, team4]}
		// 				/>
		// 			),
		// 		},
		// 	],
		// },
	],
};

export default boards;

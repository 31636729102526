import { useState, useEffect } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React templates / examples
import DashboardLayout from "layouts/templates/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/templates/Navbars/DashboardNavbar";
import DataTable from "layouts/templates/Tables/DataTable";
import Footer from "layouts/templates/Footer";

// Redux
import { useDispatch, useSelector } from "react-redux";

// Data
import dataTableData from "layouts/pages/projects/all-project-statements/data/dataTableData";

// Компонент сделан из двух дефолтных компонентов ecommerce/orders-default/order-list
// и application/data-tables (вторая таблица) + справа добавлен заголовок, описание и
// текстовые поля

function AllProjectStatements() {
	const [menu, setMenu] = useState(null);
	const { columns } = dataTableData;

	const openMenu = event => setMenu(event.currentTarget);
	const closeMenu = () => setMenu(null);

	const renderMenu = (
		<Menu
			anchorEl={menu}
			anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
			transformOrigin={{ vertical: "top", horizontal: "left" }}
			open={Boolean(menu)}
			onClose={closeMenu}
			keepMounted
		>
			<MenuItem onClick={closeMenu}>Список</MenuItem>
			<Divider sx={{ margin: "0.5rem 0" }} />
			<MenuItem onClick={closeMenu}>
				<MDTypography variant="button" color="error" fontWeight="regular">
					Удалить Фильтр
				</MDTypography>
			</MenuItem>
		</Menu>
	);

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<MDBox my={3}>
				<MDBox
					display="flex"
					justifyContent="space-between"
					alignItems="flex-start"
					mb={2}
				>
					<MDButton variant="gradient" color="info">
						НОВОЕ
					</MDButton>
					<MDBox display="flex">
						<MDBox mr={1}>
							<MDButton
								variant="gradient"
								color="info"
								component={Link}
								to="/application/project-users"
							>
								Пользователи
							</MDButton>
						</MDBox>
						<MDBox mr={1}>
							<MDButton
								variant={menu ? "contained" : "outlined"}
								color="dark"
								onClick={openMenu}
							>
								Фильтры&nbsp;
								<Icon>keyboard_arrow_down</Icon>
							</MDButton>
						</MDBox>
						{renderMenu}
						<MDBox>
							<MDButton variant="outlined" color="dark">
								<Icon>add</Icon>
								&nbsp;СПРАВОЧНИК
							</MDButton>
						</MDBox>
					</MDBox>
				</MDBox>
				<Card>
					<MDBox p={3} lineHeight={1}>
						<MDTypography variant="h5" fontWeight="medium">
							Заявления на проекте
						</MDTypography>
						<MDTypography variant="button" color="text">
							Проект:
						</MDTypography>
					</MDBox>
					<DataTable table={dataTableData} canSearch />
				</Card>
			</MDBox>
			<Footer />
		</DashboardLayout>
	);
}

export default AllProjectStatements;

// OrderList-default page components
import IdCell from "layouts/ecommerce/orders-default/order-list/components/IdCell";
import DefaultCell from "layouts/ecommerce/orders-default/order-list/components/DefaultCell";
import StatusCell from "layouts/ecommerce/orders-default/order-list/components/StatusCell";
import CustomerCell from "layouts/ecommerce/orders-default/order-list/components/CustomerCell";

// Images
import team2 from "assets/images/team-2.jpg";

const dataTableData = {
	columns: [
		{
			Header: "id заявления",
			accessor: "id",
		},
		{
			Header: "Дата добавления",
			accessor: "date",
		},
		{
			Header: "ID объекта",
			accessor: "objectId",
		},
		{
			Header: "Пользователь",
			accessor: "user",
			Cell: ({ value: [name, data] }) => (
				<CustomerCell
					image={data.image}
					color={data.color || "dark"}
					name={name}
				/>
			),
		},
		{
			Header: "Заявление",
			accessor: "statement",
		},
		{
			Header: "Дата окончания",
			accessor: "expirationDate",
		},
		{
			Header: "Статус",
			accessor: "status",
			Cell: ({ value }) => {
				let status;
				if (value === "Готово") {
					status = (
						<StatusCell icon="done" color="success" status="Согласов" />
					);
				} else if (value === "В работе") {
					status = (
						<StatusCell icon="autorenew" color="dark" status="В работе" />
					);
				} else {
					status = (
						<StatusCell icon="close" color="error" status="Отклонен" />
					);
				}

				return status;
			},
		},
	],

	rows: [
		{
			id: "#10421",
			date: "1 Ноя, 10:20",
			objectId: "#201",
			user: ["Вадим Пушилин", { image: team2 }],
			statement: "Проверка объекта",
			expirationDate: "20 Ноя, 10:20",
			status: "В работе",
		},
		{
			id: "#10421",
			date: "1 Ноя, 10:20",
			objectId: "#202",
			user: ["Вадим Пушилин", { image: team2 }],
			statement: "Монтаж потолка",
			expirationDate: "20 Ноя, 10:20",
			status: "Готово",
		},
		{
			id: "#10421",
			date: "1 Ноя, 10:20",
			objectId: "#203",
			user: ["Вадим Пушилин", { image: team2 }],
			statement: "Проверка объекта",
			expirationDate: "20 Ноя, 10:20",
			status: "Отменен",
		},
		{
			id: "#10421",
			date: "1 Ноя, 10:20",
			objectId: "#204",
			user: ["Вадим Пушилин", { image: team2 }],
			statement: "Проверка объекта",
			expirationDate: "20 Ноя, 10:20",
			status: "Готово",
		},
		{
			id: "#10421",
			date: "1 Ноя, 10:20",
			objectId: "#205",
			user: ["Вадим Пушилин", { image: team2 }],
			statement: "Проверка объекта",
			expirationDate: "20 Ноя, 10:20",
			status: "Готово",
		},
		{
			id: "#10421",
			date: "1 Ноя, 10:20",
			objectId: "#206",
			user: ["Вадим Пушилин", { image: team2 }],
			statement: "Проверка объекта",
			expirationDate: "20 Ноя, 10:20",
			status: "Отменен",
		},
	],
};

export default dataTableData;

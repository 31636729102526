// Material Dashboard 2 PRO React templates / examples
import OrderTable from "layouts/templates/Order-table";

// Data
import dataTableData from "layouts/pages/projects/project-list/data/dataTableData";

function ProjectList() {
	return (
		<OrderTable
			title="Журнал проектов"
			description="Нажмите на проект для просмотра"
			leftButtonName="Новый проект"
			leftButtonLink="/application/new-project"
			filterOptions={["Фильтр 1", "Фильтр 2", "Фильтр 3"]}
			dataTableData={dataTableData}
		/>
	);
}

export default ProjectList;

// Material Dashboard 2 PRO React templates / examples
import OrderTable from "layouts/templates/Order-table";

// Data
import dataTableData from "layouts/pages/projects/all-project-tasks/data/dataTableData";

function AllProjectTasks() {
	return (
		<OrderTable
			title="Задачи на проекте"
			description="Проект:"
			leftButtonName="Новая задача"
			rightButtonName="Справочник"
			filterOptions={["Готово", "В работе"]}
			dataTableData={dataTableData}
		/>
	);
}

export default AllProjectTasks;

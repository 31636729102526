import { combineReducers } from "redux";

// slices
import projectsReducer from "./slices/projects/projects";

// ----------------------------------------------------------------------

const rootReducer = combineReducers({
	projects: projectsReducer,
});

export { rootReducer };

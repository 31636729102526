/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React layouts
import Analytics from "layouts/dashboards/analytics";
import Sales from "layouts/dashboards/sales";
import ProfileOverview from "layouts/pages/profile/profile-overview";
import AllProjects from "layouts/pages/profile/all-projects";
// import NewUser from "layouts/pages/users/new-user";
// import Settings from "layouts/pages/account/settings";
// import Billing from "layouts/pages/account/billing";
// import Invoice from "layouts/pages/account/invoice";
import Timeline from "layouts/pages/projects/timeline";
// import PricingPage from "layouts/pages/pricing-page";
// import Widgets from "layouts/pages/widgets";
// import RTL from "layouts/pages/rtl";
// import Charts from "layouts/pages/charts";
// import Notifications from "layouts/pages/notifications";
import Kanban from "layouts/applications/kanban";
// import Wizard from "layouts/applications/wizard";
// import Calendar from "layouts/applications/calendar";
// import DataTables from "layouts/applications/data-tables";
// import NewProduct from "layouts/ecommerce/products/new-product";
// import EditProduct from "layouts/ecommerce/products/edit-product";
import ProductPage from "layouts/ecommerce/products/product-page";
import OrderList from "layouts/ecommerce/orders/order-list";
// import OrderDetails from "layouts/ecommerce/orders/order-details";
import NewOrder from "layouts/ecommerce/orders/new-order";
// import SignInBasic from "layouts/authentication/sign-in/basic";
import SignInCover from "layouts/authentication/sign-in/cover";
// import SignInIllustration from "layouts/authentication/sign-in/illustration";
import SignUpCover from "layouts/authentication/sign-up/cover";
// import ResetCover from "layouts/authentication/reset-password/cover";
import ClientList from "layouts/pages/clients/client-list";
import NewClient from "layouts/pages/clients/new-client";
import ProjectList from "layouts/pages/projects/project-list";
import EditProject from "layouts/pages/projects/edit-project";
import NewProject from "layouts/pages/projects/new-project";
import NewElement from "layouts/pages/guide/new-element";
import ElementList from "layouts/pages/guide/element-list";
import ProjectUsers from "layouts/pages/projects/project-users";

// Material Dashboard 2 PRO React components
import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";

// Images
import profilePicture from "assets/images/team-3.jpg";
import ProjectTasks from "layouts/pages/projects/project-tasks";
import ProjectStatements from "layouts/pages/projects/project-statements";
import ProjectTimeline from "layouts/pages/projects/project-timeline";
import ElementTimeline from "layouts/pages/projects/element-timeline";
import AllProjectList from "layouts/pages/projects/all-project-list";
import AllProjectTasks from "layouts/pages/projects/all-project-tasks";
import AllProjectStatements from "layouts/pages/projects/all-project-statements";
import AllProjectUsers from "layouts/pages/projects/all-project-users";

// const routes = [
// 	{
// 		type: "collapse",
// 		name: "Brooklyn Alice",
// 		key: "brooklyn-alice",
// 		icon: <MDAvatar src={profilePicture} alt="Brooklyn Alice" size="sm" />,
// 		collapse: [
// 			{
// 				name: "My Profile",
// 				key: "my-profile",
// 				route: "/pages/profile/profile-overview",
// 				component: <ProfileOverview />,
// 			},
// 			{
// 				name: "Settings",
// 				key: "profile-settings",
// 				route: "/pages/account/settings",
// 				component: <Settings />,
// 			},
// 			{
// 				name: "Logout",
// 				key: "logout",
// 				route: "/authentication/sign-in/basic",
// 				component: <SignInBasic />,
// 			},
// 		],
// 	},
// 	{ type: "divider", key: "divider-0" },
// 	{
// 		type: "collapse",
// 		name: "Dashboards",
// 		key: "dashboards",
// 		icon: <Icon fontSize="medium">dashboard</Icon>,
// 		collapse: [
// 			{
// 				name: "Analytics",
// 				key: "analytics",
// 				route: "/dashboards/analytics",
// 				component: <Analytics />,
// 			},
// 			{
// 				name: "Sales",
// 				key: "sales",
// 				route: "/dashboards/sales",
// 				component: <Sales />,
// 			},
// 		],
// 	},
// 	{ type: "title", title: "Pages", key: "title-pages" },
// 	{
// 		type: "collapse",
// 		name: "Pages",
// 		key: "pages",
// 		icon: <Icon fontSize="medium">image</Icon>,
// 		collapse: [
// 			{
// 				name: "Profile",
// 				key: "profile",
// 				collapse: [
// 					{
// 						name: "Profile Overview",
// 						key: "profile-overview",
// 						route: "/pages/profile/profile-overview",
// 						component: <ProfileOverview />,
// 					},
// 					{
// 						name: "All Projects",
// 						key: "all-projects",
// 						route: "/pages/profile/all-projects",
// 						component: <AllProjects />,
// 					},
// 				],
// 			},
// 			{
// 				name: "Users",
// 				key: "users",
// 				collapse: [
// 					{
// 						name: "New User",
// 						key: "new-user",
// 						route: "/pages/users/new-user",
// 						component: <NewUser />,
// 					},
// 				],
// 			},
// 			{
// 				name: "Account",
// 				key: "account",
// 				collapse: [
// 					{
// 						name: "Settings",
// 						key: "settings",
// 						route: "/pages/account/settings",
// 						component: <Settings />,
// 					},
// 					{
// 						name: "Billing",
// 						key: "billing",
// 						route: "/pages/account/billing",
// 						component: <Billing />,
// 					},
// 					{
// 						name: "Invoice",
// 						key: "invoice",
// 						route: "/pages/account/invoice",
// 						component: <Invoice />,
// 					},
// 				],
// 			},
// 			{
// 				name: "Projects",
// 				key: "projects",
// 				collapse: [
// 					{
// 						name: "Timeline",
// 						key: "timeline",
// 						route: "/pages/projects/timeline",
// 						component: <Timeline />,
// 					},
// 				],
// 			},
// 			{
// 				name: "Pricing Page",
// 				key: "pricing-page",
// 				route: "/pages/pricing-page",
// 				component: <PricingPage />,
// 			},
// 			{ name: "RTL", key: "rtl", route: "/pages/rtl", component: <RTL /> },
// 			{
// 				name: "Widgets",
// 				key: "widgets",
// 				route: "/pages/widgets",
// 				component: <Widgets />,
// 			},
// 			{
// 				name: "Charts",
// 				key: "charts",
// 				route: "/pages/charts",
// 				component: <Charts />,
// 			},
// 			{
// 				name: "Notfications",
// 				key: "notifications",
// 				route: "/pages/notifications",
// 				component: <Notifications />,
// 			},
// 		],
// 	},
// 	{
// 		type: "collapse",
// 		name: "Applications",
// 		key: "applications",
// 		icon: <Icon fontSize="medium">apps</Icon>,
// 		collapse: [
// 			{
// 				name: "Kanban",
// 				key: "kanban",
// 				route: "/applications/kanban",
// 				component: <Kanban />,
// 			},
// 			{
// 				name: "Wizard",
// 				key: "wizard",
// 				route: "/applications/wizard",
// 				component: <Wizard />,
// 			},
// 			{
// 				name: "Data Tables",
// 				key: "data-tables",
// 				route: "/applications/data-tables",
// 				component: <DataTables />,
// 			},
// 			{
// 				name: "Calendar",
// 				key: "calendar",
// 				route: "/applications/calendar",
// 				component: <Calendar />,
// 			},
// 		],
// 	},
// 	{
// 		type: "collapse",
// 		name: "Ecommerce",
// 		key: "ecommerce",
// 		icon: <Icon fontSize="medium">shopping_basket</Icon>,
// 		collapse: [
// 			{
// 				name: "Products",
// 				key: "products",
// 				collapse: [
// 					{
// 						name: "New Product",
// 						key: "new-product",
// 						route: "/ecommerce/products/new-product",
// 						component: <NewProduct />,
// 					},
// 					{
// 						name: "Edit Product",
// 						key: "edit-product",
// 						route: "/ecommerce/products/edit-product",
// 						component: <EditProduct />,
// 					},
// 					{
// 						name: "Product Page",
// 						key: "product-page",
// 						route: "/ecommerce/products/product-page",
// 						component: <ProductPage />,
// 					},
// 				],
// 			},
// 			{
// 				name: "Orders",
// 				key: "orders",
// 				collapse: [
// 					{
// 						name: "Order List",
// 						key: "order-list",
// 						route: "/ecommerce/orders/order-list",
// 						component: <OrderList />,
// 					},
// 					{
// 						name: "Order Details",
// 						key: "order-details",
// 						route: "/ecommerce/orders/order-details",
// 						component: <OrderDetails />,
// 					},
// 				],
// 			},
// 		],
// 	},
// 	{
// 		type: "collapse",
// 		name: "Authentication",
// 		key: "authentication",
// 		icon: <Icon fontSize="medium">content_paste</Icon>,
// 		collapse: [
// 			{
// 				name: "Sign In",
// 				key: "sign-in",
// 				collapse: [
// 					{
// 						name: "Basic",
// 						key: "basic",
// 						route: "/authentication/sign-in/basic",
// 						component: <SignInBasic />,
// 					},
// 					{
// 						name: "Cover",
// 						key: "cover",
// 						route: "/authentication/sign-in/cover",
// 						component: <SignInCover />,
// 					},
// 					{
// 						name: "Illustration",
// 						key: "illustration",
// 						route: "/authentication/sign-in/illustration",
// 						component: <SignInIllustration />,
// 					},
// 				],
// 			},
// 			{
// 				name: "Sign Up",
// 				key: "sign-up",
// 				collapse: [
// 					{
// 						name: "Cover",
// 						key: "cover",
// 						route: "/authentication/sign-up/cover",
// 						component: <SignUpCover />,
// 					},
// 				],
// 			},
// 			{
// 				name: "Reset Password",
// 				key: "reset-password",
// 				collapse: [
// 					{
// 						name: "Cover",
// 						key: "cover",
// 						route: "/authentication/reset-password/cover",
// 						component: <ResetCover />,
// 					},
// 				],
// 			},
// 		],
// 	},
// 	{ type: "divider", key: "divider-1" },
// 	{ type: "title", title: "Docs", key: "title-docs" },
// 	{
// 		type: "collapse",
// 		name: "Basic",
// 		key: "basic",
// 		icon: <Icon fontSize="medium">upcoming</Icon>,
// 		collapse: [
// 			{
// 				name: "Getting Started",
// 				key: "getting-started",
// 				collapse: [
// 					{
// 						name: "Overview",
// 						key: "overview",
// 						href: "https://www.creative-tim.com/learning-lab/react/overview/material-dashboard/",
// 					},
// 					{
// 						name: "License",
// 						key: "license",
// 						href: "https://www.creative-tim.com/learning-lab/react/license/material-dashboard/",
// 					},
// 					{
// 						name: "Quick Start",
// 						key: "quick-start",
// 						href: "https://www.creative-tim.com/learning-lab/react/quick-start/material-dashboard/",
// 					},
// 					{
// 						name: "Build Tools",
// 						key: "build-tools",
// 						href: "https://www.creative-tim.com/learning-lab/react/build-tools/material-dashboard/",
// 					},
// 				],
// 			},
// 			{
// 				name: "Foundation",
// 				key: "foundation",
// 				collapse: [
// 					{
// 						name: "Colors",
// 						key: "colors",
// 						href: "https://www.creative-tim.com/learning-lab/react/colors/material-dashboard/",
// 					},
// 					{
// 						name: "Grid",
// 						key: "grid",
// 						href: "https://www.creative-tim.com/learning-lab/react/grid/material-dashboard/",
// 					},
// 					{
// 						name: "Typography",
// 						key: "base-typography",
// 						href: "https://www.creative-tim.com/learning-lab/react/base-typography/material-dashboard/",
// 					},
// 					{
// 						name: "Borders",
// 						key: "borders",
// 						href: "https://www.creative-tim.com/learning-lab/react/borders/material-dashboard/",
// 					},
// 					{
// 						name: "Box Shadows",
// 						key: "box-shadows",
// 						href: "https://www.creative-tim.com/learning-lab/react/box-shadows/material-dashboard/",
// 					},
// 					{
// 						name: "Functions",
// 						key: "functions",
// 						href: "https://www.creative-tim.com/learning-lab/react/functions/material-dashboard/",
// 					},
// 					{
// 						name: "Routing System",
// 						key: "routing-system",
// 						href: "https://www.creative-tim.com/learning-lab/react/routing-system/material-dashboard/",
// 					},
// 				],
// 			},
// 		],
// 	},
// 	{
// 		type: "collapse",
// 		name: "Components",
// 		key: "components",
// 		icon: <Icon fontSize="medium">view_in_ar</Icon>,
// 		collapse: [
// 			{
// 				name: "Alerts",
// 				key: "alerts",
// 				href: "https://www.creative-tim.com/learning-lab/react/alerts/material-dashboard/",
// 			},
// 			{
// 				name: "Avatar",
// 				key: "avatar",
// 				href: "https://www.creative-tim.com/learning-lab/react/avatar/material-dashboard/",
// 			},
// 			{
// 				name: "Badge",
// 				key: "badge",
// 				href: "https://www.creative-tim.com/learning-lab/react/badge/material-dashboard/",
// 			},
// 			{
// 				name: "Badge Dot",
// 				key: "badge-dot",
// 				href: "https://www.creative-tim.com/learning-lab/react/badge-dot/material-dashboard/",
// 			},
// 			{
// 				name: "Box",
// 				key: "box",
// 				href: "https://www.creative-tim.com/learning-lab/react/box/material-dashboard/",
// 			},
// 			{
// 				name: "Buttons",
// 				key: "buttons",
// 				href: "https://www.creative-tim.com/learning-lab/react/buttons/material-dashboard/",
// 			},
// 			{
// 				name: "Date Picker",
// 				key: "date-picker",
// 				href: "https://www.creative-tim.com/learning-lab/react/datepicker/material-dashboard/",
// 			},
// 			{
// 				name: "Dropzone",
// 				key: "dropzone",
// 				href: "https://www.creative-tim.com/learning-lab/react/dropzone/material-dashboard/",
// 			},
// 			{
// 				name: "Editor",
// 				key: "editor",
// 				href: "https://www.creative-tim.com/learning-lab/react/quill/material-dashboard/",
// 			},
// 			{
// 				name: "Input",
// 				key: "input",
// 				href: "https://www.creative-tim.com/learning-lab/react/input/material-dashboard/",
// 			},
// 			{
// 				name: "Pagination",
// 				key: "pagination",
// 				href: "https://www.creative-tim.com/learning-lab/react/pagination/material-dashboard/",
// 			},
// 			{
// 				name: "Progress",
// 				key: "progress",
// 				href: "https://www.creative-tim.com/learning-lab/react/progress/material-dashboard/",
// 			},
// 			{
// 				name: "Snackbar",
// 				key: "snackbar",
// 				href: "https://www.creative-tim.com/learning-lab/react/snackbar/material-dashboard/",
// 			},
// 			{
// 				name: "Social Button",
// 				key: "social-button",
// 				href: "https://www.creative-tim.com/learning-lab/react/social-buttons/material-dashboard/",
// 			},
// 			{
// 				name: "Typography",
// 				key: "typography",
// 				href: "https://www.creative-tim.com/learning-lab/react/typography/material-dashboard/",
// 			},
// 		],
// 	},
// 	{
// 		type: "collapse",
// 		name: "Change Log",
// 		key: "changelog",
// 		href: "https://github.com/creativetimofficial/ct-material-dashboard-pro-react/blob/main/CHANGELOG.md",
// 		icon: <Icon fontSize="medium">receipt_long</Icon>,
// 		noCollapse: true,
// 	},
// ];

// Routes from demo.itecho.ru
// const routes = [
// 	{
// 		type: "collapse",
// 		name: "Иванова Мария",
// 		key: "Иванова Мария",
// 		icon: <MDAvatar src={profilePicture} alt="Иванова Мария" size="sm" />,
// 		collapse: [
// 			{
// 				name: "Профиль",
// 				key: "Профиль",
// 				route: "/application/profile",
// 				component: <ProfileOverview />,
// 			},
// 			{
// 				name: "Выход",
// 				key: "logout",
// 				route: "/authentication/sign-in",
// 				component: <SignInCover />,
// 			},
// 		],
// 	},
// 	{ type: "divider", key: "divider-0" },
// 	{
// 		type: "collapse",
// 		name: "Менеджер",
// 		key: "manager",
// 		icon: <Icon fontSize="medium">assessment</Icon>,
// 		collapse: [
// 			{
// 				name: "Аналитика",
// 				key: "analytics",
// 				route: "/application/analytics",
// 				component: <Analytics />,
// 			},
// 		],
// 	},
// 	{
// 		type: "collapse",
// 		name: "Заявки",
// 		key: "orders",
// 		icon: <Icon fontSize="medium">add-Circle</Icon>,
// 		collapse: [
// 			{
// 				name: "Новая заявка",
// 				key: "new-order",
// 				route: "/application/new-order",
// 				component: <NewOrder />,
// 			},
// 			{
// 				name: "Список заявок",
// 				key: "order-list",
// 				route: "/application/order-list",
// 				component: <OrderList />,
// 			},
// 		],
// 	},
// 	{
// 		type: "collapse",
// 		name: "Клиенты",
// 		key: "clients",
// 		icon: <Icon fontSize="medium">group</Icon>,
// 		collapse: [
// 			{
// 				name: "Новый клиент",
// 				key: "new-client",
// 				route: "/application/new-client",
// 				component: <NewClient />,
// 			},
// 			{
// 				name: "База клиентов",
// 				key: "client-list",
// 				route: "/application/client-list",
// 				component: <ClientList />,
// 			},
// 		],
// 	},
// 	{ type: "divider", key: "divider-1" },
// 	{
// 		type: "collapse",
// 		name: "Склад",
// 		key: "warehouse",
// 		icon: <Icon fontSize="medium">summarize</Icon>,
// 		collapse: [
// 			{
// 				name: "Новая заявка",
// 				key: "new-order",
// 				route: "/application/new-order",
// 				component: <NewOrder />,
// 			},
// 			{
// 				name: "Грузы",
// 				key: "cargo",
// 				route: "/application/cargo",
// 			},
// 			{
// 				name: "Отчеты",
// 				key: "reports",
// 				route: "/application/reports",
// 			},
// 		],
// 	},
// 	{ type: "divider", key: "divider-2" },
// 	{
// 		type: "collapse",
// 		name: "Бухгалтер",
// 		key: "accountant",
// 		icon: <Icon fontSize="medium">payment</Icon>,
// 		collapse: [
// 			{
// 				name: "Продажи",
// 				key: "sales",
// 				route: "/application/sales",
// 				component: <Sales />,
// 			},
// 			{
// 				name: "Выгрузка в 1С",
// 				key: "Export-to-1C",
// 				route: "/application/accountant/Export-to-1C",
// 			},
// 			{
// 				name: "Отчеты",
// 				key: "reports",
// 				route: "/application/director/reports",
// 			},
// 		],
// 	},
// 	{ type: "divider", key: "divider-3" },
// 	{
// 		type: "collapse",
// 		name: "Руководитель",
// 		key: "director",
// 		icon: <Icon fontSize="medium">dashboard</Icon>,
// 		collapse: [
// 			{
// 				name: "Продажи",
// 				key: "sales",
// 				route: "/application/sales",
// 				component: <Sales />,
// 			},
// 			{
// 				name: "Отчеты",
// 				key: "reports",
// 				route: "/application/director/reports",
// 			},
// 		],
// 	},
// 	{ type: "divider", key: "divider-4" },
// 	{
// 		name: "Выход",
// 		key: "sign-up",
// 		route: "/authentication/sign-up",
// 		component: <SignUpCover />,
// 	},
// 	{
// 		name: "Product Page",
// 		key: "product-page",
// 		route: "/application/product-page",
// 		component: <ProductPage />,
// 	},
// 	{
// 		type: "collapse",
// 		noCollapse: true,
// 		name: "Справочник",
// 		key: "guide",
// 		route: "/application/guide",
// 		component: <ElementList />,
// 	},
// 	{
// 		type: "collapse",
// 		noCollapse: true,
// 		name: "Новый элемент",
// 		key: "new-element",
// 		route: "/application/new-element",
// 		component: <NewElement />,
// 	},
// 	{
// 		type: "collapse",
// 		noCollapse: true,
// 		name: "Проекты",
// 		key: "all-projects",
// 		route: "/pages/profile/all-projects",
// 		component: <AllProjects />,
// 	},
// 	{
// 		type: "collapse",
// 		noCollapse: true,
// 		name: "Журнал проектов",
// 		key: "projects-list",
// 		route: "/application/projects-list",
// 		component: <ProjectList />,
// 	},
// 	{
// 		type: "collapse",
// 		noCollapse: true,
// 		name: "Редактор проекта",
// 		key: "edit-project",
// 		route: "/application/edit-project",
// 		component: <EditProject />,
// 	},
// 	{
// 		type: "collapse",
// 		noCollapse: true,
// 		name: "Новый проект",
// 		key: "new-project",
// 		route: "/application/new-project",
// 		component: <NewProject />,
// 	},
// 	{
// 		type: "collapse",
// 		noCollapse: true,
// 		name: "Пользователи проекта",
// 		key: "project-users",
// 		route: "/application/project-users",
// 		component: <ProjectUsers />,
// 	},
// 	{
// 		type: "collapse",
// 		noCollapse: true,
// 		name: "Задачи на проекте",
// 		key: "project-tasks",
// 		route: "/application/project-tasks",
// 		component: <ProjectTasks />,
// 	},
// ];

const routes = [
	{
		type: "collapse",
		name: "Иванова Мария",
		key: "Иванова Мария",
		icon: <MDAvatar src={profilePicture} alt="Иванова Мария" size="sm" />,
		collapse: [
			{
				name: "Профиль",
				key: "Профиль",
				route: "/application/profile",
				component: <ProfileOverview />,
			},
			{
				name: "Выход",
				key: "logout",
				route: "/authentication/sign-in",
				component: <SignInCover />,
			},
		],
	},
	{ type: "divider", key: "divider-0" },
	{
		type: "collapse",
		name: "Справочник",
		key: "guide",
		icon: <Icon fontSize="medium">assessment</Icon>,
		collapse: [
			{
				type: "collapse",
				noCollapse: true,
				name: "Список",
				key: "element-list",
				route: "/application/element-list",
				component: <ElementList />,
			},
			{
				type: "collapse",
				noCollapse: true,
				name: "Новый элемент",
				key: "new-element",
				route: "/application/new-element",
				component: <NewElement />,
			},
		],
	},
	{ type: "divider", key: "divider-1" },
	{
		type: "collapse",
		name: "Проекты",
		key: "projects",
		icon: <Icon fontSize="medium">assessment</Icon>,
		collapse: [
			{
				name: "Проекты",
				key: "all-projects",
				route: "/application/all-projects",
				component: <AllProjects />,
			},
			{
				name: "Список",
				key: "all-project-list",
				route: "/application/all-project-list",
				component: <AllProjectList />,
			},
			{
				name: "Задачи",
				key: "all-project-tasks",
				route: "/application/all-project-tasks",
				component: <AllProjectTasks />,
			},
			{
				name: "Заявления",
				key: "all-project-statements",
				route: "/application/all-project-statements",
				component: <AllProjectStatements />,
			},
			{
				name: "Пользователи",
				key: "all-project-users",
				route: "/application/all-project-users",
				component: <AllProjectUsers />,
			},
			{
				name: "Новый",
				key: "new-project",
				route: "/application/new-project",
				component: <NewProject />,
			},
		],
	},
	{ type: "divider", key: "divider-2" },
	{
		type: "collapse",
		noCollapse: true,
		name: "Kanban",
		key: "kanban",
		route: "/applications/kanban",
		component: <Kanban />,
	},
	{
		name: "Project-timeline",
		key: "project-timeline",
		route: "/application/project-timeline",
		component: <ProjectTimeline />,
	},
	{
		name: "Element-timeline",
		key: "element-timeline",
		route: "/application/element-timeline",
		component: <ElementTimeline />,
	},
	{
		name: "Выход",
		key: "sign-up",
		route: "/authentication/sign-up",
		component: <SignUpCover />,
	},
	{
		name: "Редактор проекта",
		key: "edit-project",
		route: "/application/edit-project",
		component: <EditProject />,
	},
	{
		name: "Список",
		key: "project-list",
		route: "/application/project-list",
		component: <ProjectList />,
	},
	{
		name: "Задачи",
		key: "project-tasks",
		route: "/application/project-tasks",
		component: <ProjectTasks />,
	},
	{
		name: "Заявления",
		key: "project-statements",
		route: "/application/project-statements",
		component: <ProjectStatements />,
	},
	{
		name: "Пользователи",
		key: "project-users",
		route: "/application/project-users",
		component: <ProjectUsers />,
	},

	{
		name: "Аналитика",
		key: "analytics",
		route: "/application/analytics",
		component: <Analytics />,
	},
];

export default routes;

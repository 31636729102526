import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React templates / examples
import DashboardLayout from "layouts/templates/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/templates/Navbars/DashboardNavbar";
import DataTable from "layouts/templates/Tables/DataTable";
import Footer from "layouts/templates/Footer";

// Data
import dataTableData from "layouts/pages/projects/edit-project/data/dataTableData";

// Компонент сделан из двух дефолтных компонентов ecommerce/orders-default/order-list
// и application/data-tables (вторая таблица) + справа добавлен заголовок, описание и
// текстовые поля

function EditProject() {
	const [menu, setMenu] = useState(null);

	const openMenu = event => setMenu(event.currentTarget);
	const closeMenu = () => setMenu(null);

	const renderMenu = (
		<Menu
			anchorEl={menu}
			anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
			transformOrigin={{ vertical: "top", horizontal: "left" }}
			open={Boolean(menu)}
			onClose={closeMenu}
			keepMounted
		>
			<MenuItem onClick={closeMenu}>Список</MenuItem>
			<Divider sx={{ margin: "0.5rem 0" }} />
			<MenuItem onClick={closeMenu}>
				<MDTypography variant="button" color="error" fontWeight="regular">
					Удалить Фильтр
				</MDTypography>
			</MenuItem>
		</Menu>
	);

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<MDBox my={3}>
				<MDBox
					display="flex"
					flexWrap="wrap"
					justifyContent="space-between"
					gap={1}
					mb={2}
				>
					<MDButton variant="gradient" color="info" component={Link} to="">
						Редактировать
					</MDButton>
					<MDBox display="flex" flexWrap="wrap" gap={1}>
						<MDButton
							variant="gradient"
							color="info"
							component={Link}
							to="/application/project-users"
						>
							Пользователи
						</MDButton>
						<MDButton
							variant={menu ? "contained" : "outlined"}
							color="dark"
							onClick={openMenu}
						>
							Фильтры&nbsp;
							<Icon>keyboard_arrow_down</Icon>
						</MDButton>
						{renderMenu}
						<MDButton variant="outlined" color="dark">
							<Icon>add</Icon>
							&nbsp;СПРАВОЧНИК
						</MDButton>
					</MDBox>
				</MDBox>
				<Card>
					<MDBox
						p={3}
						lineHeight={1}
						display="flex"
						flexWrap="wrap"
						gap={1}
						justifyContent="space-between"
					>
						<MDBox>
							<MDTypography variant="h5" fontWeight="medium" mb="6px">
								Проект 77
							</MDTypography>
							<MDBox display="flex" alignItems="center">
								<MDTypography variant="button" color="text">
									Объект:
								</MDTypography>
								<MDBox ml="6px">
									<MDInput
										placeholder="Введите название"
										size="small"
										value="Петухова 130/1"
									/>
								</MDBox>
							</MDBox>
						</MDBox>
						<MDBox>
							<MDBox
								display="flex"
								alignItems="center"
								justifyContent="flex-end"
								gap={1}
								mb="6px"
							>
								<MDTypography variant="h5" fontWeight="medium">
									Приложение №
								</MDTypography>
								<MDBox ml="6px" width="50px">
									<MDInput size="small" value="1" />
								</MDBox>
							</MDBox>
							<MDBox display="flex" alignItems="center" gap={1}>
								<MDTypography
									variant="button"
									color="text"
									component="span"
								>
									к Договору подряда №
								</MDTypography>
								<MDBox mx="6px" width="50px">
									<MDInput size="small" />
								</MDBox>
								<MDTypography
									variant="button"
									color="text"
									component="span"
								>
									от
								</MDTypography>
								<MDBox mx="6px" width="100px">
									<MDInput placeholder="Введите дату" size="small" />
								</MDBox>
								<MDTypography
									variant="button"
									color="text"
									component="span"
								>
									г.
								</MDTypography>
							</MDBox>
						</MDBox>
					</MDBox>
					<DataTable table={dataTableData} canSearch />
				</Card>
			</MDBox>
			<Footer />
		</DashboardLayout>
	);
}

export default EditProject;

import { createSlice } from "@reduxjs/toolkit";

// ----------------------------------------------------------------------

const initialState = {
	newProject: [],
};

const slice = createSlice({
	name: "projects",
	initialState,
	reducers: {
		setNewProject(state, action) {
			state.newProject = action.payload;
		},
	},
});

// Reducer
export default slice.reducer;

export const { setNewProject } = slice.actions;

// ----------------------------------------------------------------------

// export function getClients() {
// 	return async dispatch => {
// 		const response = await axios.get(`api/v1/users`);
// 		const { data } = response.data.Users;
// 		dispatch(slice.actions.getClientsSuccess({ clients: data }));
// 	};
// }

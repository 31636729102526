/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React templates / examples
import DashboardLayout from "layouts/templates/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/templates/Navbars/DashboardNavbar";
import DataTable from "layouts/templates/Tables/DataTable";
import Footer from "layouts/templates/Footer";

// Компонент сделан из двух дефолтных компонентов ecommerce/orders-default/order-list
// и application/data-tables (вторая таблица)

function OrderTable({
	title,
	description,
	leftButtonName,
	leftButtonLink,
	rightButtonName,
	rightButtonLink,
	filterOptions,
	rowLink,
	dataTableData,
}) {
	const [menu, setMenu] = useState(null);

	const openMenu = event => setMenu(event.currentTarget);
	const closeMenu = () => setMenu(null);

	const renderMenu = (
		<Menu
			anchorEl={menu}
			anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
			transformOrigin={{ vertical: "top", horizontal: "left" }}
			open={Boolean(menu)}
			onClose={closeMenu}
			keepMounted
		>
			{filterOptions ? (
				filterOptions.map(element => {
					return <MenuItem onClick={closeMenu}>{element}</MenuItem>;
				})
			) : (
				<></>
			)}
			<Divider sx={{ margin: "0.5rem 0" }} />
			<MenuItem onClick={closeMenu}>
				<MDTypography variant="button" color="error" fontWeight="regular">
					Удалить Фильтр
				</MDTypography>
			</MenuItem>
		</Menu>
	);

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<MDBox my={3}>
				<MDBox
					display="flex"
					justifyContent="space-between"
					alignItems="flex-start"
					mb={2}
				>
					<MDButton
						variant="gradient"
						color="info"
						component={Link}
						to={leftButtonLink}
					>
						{leftButtonName}
					</MDButton>
					<MDBox display="flex" gap={1}>
						<MDButton
							variant={menu ? "contained" : "outlined"}
							color="dark"
							onClick={openMenu}
						>
							Фильтры&nbsp;
							<Icon>keyboard_arrow_down</Icon>
						</MDButton>
						{renderMenu}
						<MDBox>
							{rightButtonName ? (
								<MDButton
									variant="outlined"
									color="dark"
									component={Link}
									to={rightButtonLink}
								>
									<Icon>add</Icon>
									&nbsp;{rightButtonName}
								</MDButton>
							) : (
								<MDButton variant="outlined" color="dark">
									<Icon>description</Icon>
									&nbsp;Экспорт csv
								</MDButton>
							)}
						</MDBox>
					</MDBox>
				</MDBox>
				<Card>
					<MDBox p={3} lineHeight={1}>
						<MDTypography variant="h5" fontWeight="medium">
							{title}
						</MDTypography>
						<MDTypography variant="button" color="text">
							{description}
						</MDTypography>
					</MDBox>
					<DataTable table={dataTableData} canSearch />
				</Card>
			</MDBox>
			<Footer />
		</DashboardLayout>
	);
}

// Typechecking props for the OrderTable
OrderTable.propTypes = {
	title: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	leftButtonName: PropTypes.string.isRequired,
	leftButtonLink: PropTypes.string,
	rightButtonName: PropTypes.string,
	rightButtonLink: PropTypes.string,
	rowLink: PropTypes.string,
	filterOptions: PropTypes.array,
	dataTableData: PropTypes.object,
};

export default OrderTable;

// Material Dashboard 2 PRO React templates / examples
import OrderTable from "layouts/templates/Order-table";

// Data
import dataTableData from "layouts/pages/guide/element-list/data/dataTableData";

function ElementList() {
	return (
		<OrderTable
			title="Справочник"
			description="Справочник материалов и работ"
			leftButtonName="Добавить"
			leftButtonLink="/application/new-element"
			filterOptions={["Услуги", "Материалы"]}
			dataTableData={dataTableData}
		/>
	);
}

export default ElementList;

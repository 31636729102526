/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

function ProjectMenuCell({ status }) {
	const [menu, setMenu] = useState(null);

	const openMenu = event => setMenu(event.currentTarget);
	const closeMenu = () => setMenu(null);

	const renderMenu = (
		<Menu
			anchorEl={menu}
			anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
			transformOrigin={{ vertical: "top", horizontal: "left" }}
			open={Boolean(menu)}
			onClose={closeMenu}
			keepMounted
		>
			<MenuItem
				onClick={closeMenu}
				component={Link}
				to="/application/edit-project"
			>
				<MDTypography variant="button" color="text" fontWeight="regular">
					Редактирование
				</MDTypography>
			</MenuItem>
			<MenuItem
				onClick={closeMenu}
				component={Link}
				to="/application/project-timeline"
			>
				<MDTypography variant="button" color="text" fontWeight="regular">
					История
				</MDTypography>
			</MenuItem>
			<MenuItem
				onClick={close}
				component={Link}
				to="/application/project-tasks"
			>
				Задачи
			</MenuItem>
			<MenuItem
				onClick={close}
				component={Link}
				to="/application/project-users"
			>
				Пользователи
			</MenuItem>
			<MenuItem
				onClick={close}
				component={Link}
				to="/application/project-statements"
			>
				Заявления
			</MenuItem>
		</Menu>
	);

	return (
		<MDBox display="flex" alignItems="center" gap={1}>
			<MDButton
				variant="outlined"
				color="dark"
				size="small"
				iconOnly
				circular
				onClick={openMenu}
			>
				<Icon>settings</Icon>
			</MDButton>
			{renderMenu}
			<MDTypography
				variant="caption"
				fontWeight="medium"
				color="text"
				sx={{ lineHeight: 0 }}
			>
				{status}
			</MDTypography>
		</MDBox>
	);
}

// Typechecking props for the ProjectMenuCell
ProjectMenuCell.propTypes = {
	status: PropTypes.string.isRequired,
};

export default ProjectMenuCell;

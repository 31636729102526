/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import { useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Autocomplete from "@mui/material/Autocomplete";

// Settings page components
import MDInput from "components/MDInput";

function UnitsMenuCell() {
	return (
		<Autocomplete
			defaultValue="м2"
			options={["м2", "м.п", "м3", "ед."]}
			renderInput={params => <MDInput {...params} variant="standard" />}
		/>
	);
}

// Typechecking props for the UnitsMenuCell
// UnitsMenuCell.propTypes = {
// 	status: PropTypes.string.isRequired,
// };

export default UnitsMenuCell;

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";

// ProductsList page components
import IdCell from "layouts/ecommerce/orders-default/order-list/components/IdCell";
import DefaultCell from "layouts/ecommerce/orders-default/order-list/components/DefaultCell";

const dataTableData = {
	columns: [
		{
			Header: "id",
			accessor: "id",
			Cell: ({ value }) => <IdCell id={value} />,
		},
		{
			Header: "Дата созд.",
			accessor: "date",
		},
		{
			Header: "Наименование, ед.изм",
			accessor: "name",
			Cell: ({ value }) => {
				if (value[1] === 1) {
					return <DefaultCell value={value[0]} />;
				} else {
					return (
						<MDTypography
							variant="body2"
							fontWeight="regular"
							color="text"
							fontSize="14px"
							ml="15px"
						>
							- {value[0]}
						</MDTypography>
					);
				}
			},
		},
		{
			Header: "Себесто-сть",
			accessor: "сostPrice",
		},
		{
			Header: "Наценка, %",
			accessor: "markup",
		},
		{
			Header: "Стоимость",
			accessor: "price",
		},
	],

	rows: [
		{
			id: "#10421",
			date: "1 Ноя, 10:20",
			name: ["Проектирование, м2", 1],
			сostPrice: "350",
			markup: "40",
			price: "490",
		},
		{
			id: "#10422",
			date: "1 Ноя, 10:47",
			name: ["Протокол замера сопротивления", 0],
			сostPrice: "350",
			markup: "м2",
			price: "Услуги",
		},
		{
			id: "#10423",
			date: "1 Ноя, 15:30",
			name: ["Рабочий проект АР, ЭО, ОВиК", 0],
			сostPrice: "350",
			markup: "м2",
			price: "Услуги",
		},
		{
			id: "#10424",
			date: "2 Ноя, 15:30",
			name: ["Проектирование, м2", 1],
			сostPrice: "350",
			markup: "40",
			price: "490",
		},
		{
			id: "#10425",
			date: "2 Ноя, 16:30",
			name: ["Проектирование, м2", 1],
			сostPrice: "350",
			markup: "40",
			price: "490",
		},
		{
			id: "#10426",
			date: "2 Ноя, 16:30",
			name: ["Проектирование, м2", 1],
			сostPrice: "350",
			markup: "40",
			price: "490",
		},
		{
			id: "#10427",
			date: "2 Ноя, 17:10",
			name: ["Проектирование, м2", 1],
			сostPrice: "350",
			markup: "40",
			price: "490",
		},
		{
			id: "#10428",
			date: "3 Ноя, 14:30",
			name: ["Проектирование, м2", 1],
			сostPrice: "350",
			markup: "40",
			price: "490",
		},
		{
			id: "#10429",
			date: "4 Ноя, 11:19",
			name: ["Проектирование, м2", 1],
			сostPrice: "350",
			markup: "40",
			price: "490",
		},
		{
			id: "#10430",
			date: "4 Ноя, 13:19",
			name: ["Проектирование, м2", 1],
			сostPrice: "350",
			markup: "40",
			price: "490",
		},
		{
			id: "#10431",
			date: "2 Ноя, 15:55",
			name: ["Проектирование, м2", 1],
			сostPrice: "350",
			markup: "40",
			price: "490",
		},
		{
			id: "#10432",
			date: "5 Ноя, 19:10",
			name: ["Проектирование, м2", 1],
			сostPrice: "350",
			markup: "40",
			price: "490",
		},
	],
};

export default dataTableData;

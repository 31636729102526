/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "layouts/templates/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/templates/Navbars/DashboardNavbar";
import Footer from "layouts/templates/Footer";

// NewElement page components
import ElementInfo from "layouts/pages/guide/new-element/components/ElementInfo";
import Price from "layouts/pages/guide/new-element/components/Price";

// Компонент сделан из дефолтного компонента /ecommerce/products/new-product

function getSteps() {
	return ["ИНФОРМАЦИЯ", "ЦЕНА"];
}

function getStepContent(stepIndex) {
	switch (stepIndex) {
		case 0:
			return <ElementInfo />;
		case 1:
			return <Price />;
		default:
			return null;
	}
}

function NewElement() {
	const [activeStep, setActiveStep] = useState(0);
	const steps = getSteps();
	const isLastStep = activeStep === steps.length - 1;
	const navigate = useNavigate();

	const handleNext = () => setActiveStep(activeStep + 1);
	const handleBack = () => setActiveStep(activeStep - 1);
	const handleEnd = () => navigate("/application/element-list");
	return (
		<DashboardLayout>
			<DashboardNavbar />
			<MDBox mt={5} mb={9}>
				<Grid container justifyContent="center">
					<Grid item xs={12} lg={8}>
						<MDBox mt={6} mb={8} textAlign="center">
							<MDBox mb={1}>
								<MDTypography variant="h3" fontWeight="bold">
									Создание/редактирование элемента справочника
								</MDTypography>
							</MDBox>
							<MDTypography
								variant="h5"
								fontWeight="regular"
								color="secondary"
							>
								Добавьте наименование услуги или материала
							</MDTypography>
						</MDBox>
						<Card>
							<MDBox mt={-3} mb={3} mx={2}>
								<Stepper activeStep={activeStep} alternativeLabel>
									{steps.map(label => (
										<Step key={label}>
											<StepLabel>{label}</StepLabel>
										</Step>
									))}
								</Stepper>
							</MDBox>
							<MDBox p={2}>
								<MDBox>
									{getStepContent(activeStep)}
									<MDBox
										mt={3}
										width="100%"
										display="flex"
										justifyContent="space-between"
									>
										{activeStep === 0 ? (
											<MDBox />
										) : (
											<MDButton
												variant="gradient"
												color="light"
												onClick={handleBack}
											>
												НАЗАД
											</MDButton>
										)}
										<MDButton
											variant="gradient"
											color="dark"
											onClick={!isLastStep ? handleNext : handleEnd}
										>
											{isLastStep ? "Добавить" : "Далее"}
										</MDButton>
									</MDBox>
								</MDBox>
							</MDBox>
						</Card>
					</Grid>
				</Grid>
			</MDBox>
			<Footer />
		</DashboardLayout>
	);
}

export default NewElement;

/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// NewProduct page components
import FormField from "layouts/ecommerce/products/new-product/components/FormField";

function ElementInfo() {
	return (
		<MDBox>
			<MDTypography variant="h5">Информация</MDTypography>
			<MDBox mt={3}>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={6}>
						<FormField
							type="text"
							label="Наименование как поиск моделей"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<FormField
							type="text"
							label="Введите наименование родителя"
						/>
					</Grid>
				</Grid>
			</MDBox>
			<MDBox mt={2}>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={6}>
						<MDBox mb={3}>
							<MDBox mb={2} display="inline-block">
								<MDTypography
									component="label"
									variant="button"
									fontWeight="regular"
									color="text"
									textTransform="capitalize"
								>
									Категория
								</MDTypography>
							</MDBox>
							<Autocomplete
								defaultValue="Услуга"
								options={["Услуга", "Материал", "Задача"]}
								renderInput={params => (
									<MDInput {...params} variant="standard" />
								)}
							/>
						</MDBox>
					</Grid>
					<Grid item xs={12} sm={6}>
						<MDBox mb={2} display="inline-block">
							<MDTypography
								component="label"
								variant="button"
								fontWeight="regular"
								color="text"
								textTransform="capitalize"
							>
								Ед. изм.
							</MDTypography>
						</MDBox>
						<Autocomplete
							defaultValue="м2"
							options={["м2", "м.п", "м3", "ед."]}
							renderInput={params => (
								<MDInput {...params} variant="standard" />
							)}
						/>
					</Grid>
				</Grid>
			</MDBox>
		</MDBox>
	);
}

export default ElementInfo;

import { useState, useEffect } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React templates / examples
import DashboardLayout from "layouts/templates/LayoutContainers/DashboardLayout";
import DashboardNavbar from "layouts/templates/Navbars/DashboardNavbar";
import DataTable from "layouts/templates/Tables/DataTable";
import Footer from "layouts/templates/Footer";

// Redux
import { useDispatch, useSelector } from "react-redux";

// Data
import dataTableData from "layouts/pages/projects/new-project/data/dataTableData";
import { setNewProject } from "redux/slices/projects/projects";

// Компонент сделан из двух дефолтных компонентов ecommerce/orders-default/order-list
// и application/data-tables (вторая таблица) + справа добавлен заголовок, описание и
// текстовые поля

function NewProject() {
	const [menu, setMenu] = useState(null);
	const { columns } = dataTableData;

	const openMenu = event => setMenu(event.currentTarget);
	const closeMenu = () => setMenu(null);

	const renderMenu = (
		<Menu
			anchorEl={menu}
			anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
			transformOrigin={{ vertical: "top", horizontal: "left" }}
			open={Boolean(menu)}
			onClose={closeMenu}
			keepMounted
		>
			<MenuItem onClick={closeMenu}>Список</MenuItem>
			<Divider sx={{ margin: "0.5rem 0" }} />
			<MenuItem onClick={closeMenu}>
				<MDTypography variant="button" color="error" fontWeight="regular">
					Удалить Фильтр
				</MDTypography>
			</MenuItem>
		</Menu>
	);

	const rows = [
		{
			works: ["Проектирование", 1],
			units: ["м2", 1],
			quantity: ["639,1", 1],
			price: ["522", 1],
			cost: ["333 610,2", 1],
			status: "Согласов.",
		},
		{
			works: ["Протокол замера сопротивления", 2],
			units: ["м2", 1],
			quantity: ["291,5", 1],
			price: ["180", 1],
			cost: ["52 470", 1],
			status: "Выполнен",
		},
		{
			works: ["Рабочий проект АР, ЭО, ОВиК", 2],
			units: ["м2", 1],
			quantity: ["244,3", 1],
			price: ["90", 1],
			cost: ["21 987", 1],
			status: "Выполнен",
		},
		{
			works: ["Рабочий проект АУПТ, АПС, СОУЭ", 2],
			units: ["м2", 1],
			quantity: ["919,2", 1],
			price: ["830", 1],
			cost: ["762 936", 1],
			status: "Выполнен",
		},
		{
			works: ["Согласования", 1],
			units: ["м2", 1],
			quantity: ["101,8", 1],
			price: ["491", 1],
			cost: ["49 983,8", 1],
			status: "Выполнен",
		},
		{
			works: ["Протокол замера сопротивления", 2],
			units: ["м2", 1],
			quantity: ["983", 1],
			price: ["371", 1],
			cost: ["364 693", 1],
			status: "Выполнен",
		},
		{
			works: ["Страхование объекта", 2],
			units: ["м2", 1],
			quantity: ["229,6", 1],
			price: ["943", 1],
			cost: ["216 512,8", 1],
			status: "Выполнен",
		},
		{
			works: ["Строительство", 1],
			units: ["м2", 1],
			quantity: ["54,3", 1],
			price: ["873", 1],
			cost: ["47 403,9", 1],
			status: "Выполнен",
		},
		{
			works: ["Общестроительные работы", 1],
			units: ["м2", 1],
			quantity: ["120,7", 1],
			price: ["769", 1],
			cost: ["92 818,3", 1],
			status: "Выполнен",
		},
		{
			works: ["Протокол замера сопротивления", 2],
			units: ["м2", 1],
			quantity: ["131,9", 1],
			price: ["948", 1],
			cost: ["125 041,2", 1],
			status: "Выполнен",
		},
	];

	const dispatch = useDispatch();
	const { newProject } = useSelector(state => state.projects);
	console.log(newProject);
	useEffect(() => {
		async function fetchData() {
			dispatch(setNewProject(rows));
		}
		fetchData();
	}, []);

	const handleCreateRow = () => {
		dispatch(
			setNewProject([
				...newProject,
				{
					works: ["Введите название", 0],
					units: ["м2", 0],
					quantity: ["_____", 0],
					price: ["____", 0],
					cost: ["___________", 0],
					status: "Черновик",
				},
			])
		);
	};

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<MDBox my={3}>
				<MDBox
					display="flex"
					justifyContent="space-between"
					alignItems="flex-start"
					mb={2}
				>
					<MDButton
						variant="gradient"
						color="info"
						onClick={handleCreateRow}
					>
						Новый элемент
					</MDButton>
					<MDBox display="flex">
						<MDBox mr={1}>
							<MDButton
								variant="gradient"
								color="info"
								component={Link}
								to="/application/project-users"
							>
								Пользователи
							</MDButton>
						</MDBox>
						<MDBox mr={1}>
							<MDButton
								variant={menu ? "contained" : "outlined"}
								color="dark"
								onClick={openMenu}
							>
								Фильтры&nbsp;
								<Icon>keyboard_arrow_down</Icon>
							</MDButton>
						</MDBox>
						{renderMenu}
						<MDBox>
							<MDButton variant="outlined" color="dark">
								<Icon>add</Icon>
								&nbsp;СПРАВОЧНИК
							</MDButton>
						</MDBox>
					</MDBox>
				</MDBox>
				<Card>
					<MDBox
						p={3}
						lineHeight={1}
						display="flex"
						justifyContent="space-between"
					>
						<MDBox>
							<MDTypography variant="h5" fontWeight="medium" mb={1}>
								Создание проекта
							</MDTypography>
							<MDBox display="flex" alignItems="flex-end" mt={1}>
								<MDTypography variant="button" color="text">
									Объект:
								</MDTypography>
								<MDBox ml="20px">
									<TextField
										id="standard-basic"
										label="Введите название"
										variant="standard"
									/>
								</MDBox>
							</MDBox>
						</MDBox>

						<MDBox>
							<MDBox
								display="flex"
								alignItems="center"
								justifyContent="flex-end"
								mb={1}
							>
								<MDTypography variant="h5" fontWeight="medium">
									Приложение №
								</MDTypography>
								<MDBox ml="20px" width="50px">
									<TextField
										id="standard-basic"
										label=""
										variant="standard"
									/>
								</MDBox>
							</MDBox>
							<MDBox display="flex" alignItems="flex-end">
								<MDTypography
									variant="button"
									color="text"
									component="span"
								>
									к Договору подряда №
								</MDTypography>
								<MDBox mx="20px" width="50px">
									<TextField
										id="standard-basic"
										label="Ввод"
										variant="standard"
									/>
								</MDBox>
								<MDTypography
									variant="button"
									color="text"
									component="span"
								>
									от
								</MDTypography>
								<MDBox mx="20px" width="100px">
									<TextField
										id="standard-basic"
										label="дд.мм.гг"
										variant="standard"
									/>
								</MDBox>
								<MDTypography
									variant="button"
									color="text"
									component="span"
								>
									г.
								</MDTypography>
							</MDBox>
						</MDBox>
					</MDBox>
					<DataTable table={{ columns, rows: newProject }} canSearch />
				</Card>
			</MDBox>
			<Footer />
		</DashboardLayout>
	);
}

export default NewProject;

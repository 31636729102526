// OrderTable page components
import IdCell from "layouts/templates/Order-table/components/IdCell";
import DefaultCell from "layouts/templates/Order-table/components/DefaultCell";
import ProjectMenuCell from "layouts/templates/Order-table/components/ProjectMenuCell";

const dataTableData = {
	columns: [
		{
			Header: "ID объекта",
			accessor: "id",
		},
		{
			Header: "Заказчик",
			accessor: "customer",
		},
		{
			Header: "Адрес объекта",
			accessor: "address",
		},
		{
			Header: "Дата/Договор",
			accessor: "dateContract",
		},
		{
			Header: "Оконч/Стоимость.",
			accessor: "finalCost",
		},
		{
			Header: "Себестоимость",
			accessor: "costPrice",
		},
		{
			Header: "Статус",
			accessor: "status",
			Cell: ({ value }) => <ProjectMenuCell status={value} />,
		},
	],

	rows: [
		{
			id: "#201",
			customer: "ООО “Каркаде”",
			address: "Новосибирск, Станционнаая, 30в",
			dateContract: "20.05.23/666Б",
			finalCost: "20.05.24/ 585,89тыс.р",
			costPrice: "350,1 тыс.р",
			status: "Создан",
		},
		{
			id: "#202",
			customer: "ИП Демидов П.В.",
			address: "Новосибирск, Петухова 102",
			dateContract: "20.05.23/666Б",
			finalCost: "20.05.24/ 585,89тыс.р",
			costPrice: "350,1 тыс.р",
			status: "Завершен",
		},
		{
			id: "#203",
			customer: "ООО “Каркаде”",
			address: "Новосибирск, Станционнаая, 30в",
			dateContract: "20.05.23/666Б",
			finalCost: "20.05.24/ 585,89тыс.р",
			costPrice: "350,1 тыс.р",
			status: "Завершен",
		},
		{
			id: "#204",
			customer: "ООО “Каркаде”",
			address: "Новосибирск, Станционнаая, 30в",
			dateContract: "20.05.23/666Б",
			finalCost: "20.05.24/ 585,89тыс.р",
			costPrice: "350,1 тыс.р",
			status: "Завершен",
		},
		{
			id: "#205",
			customer: "ООО “Каркаде”",
			address: "Новосибирск, Станционнаая, 30в",
			dateContract: "20.05.23/666Б",
			finalCost: "20.05.24/ 585,89тыс.р",
			costPrice: "350,1 тыс.р",
			status: "Завершен",
		},
		{
			id: "#206",
			customer: "ООО “Каркаде”",
			address: "Новосибирск, Станционнаая, 30в",
			dateContract: "20.05.23/666Б",
			finalCost: "20.05.24/ 585,89тыс.р",
			costPrice: "350,1 тыс.р",
			status: "Завершен",
		},
		{
			id: "#207",
			customer: "ООО “Каркаде”",
			address: "Новосибирск, Станционнаая, 30в",
			dateContract: "20.05.23/666Б",
			finalCost: "20.05.24/ 585,89тыс.р",
			costPrice: "350,1 тыс.р",
			status: "Завершен",
		},
		{
			id: "#208",
			customer: "ООО “Каркаде”",
			address: "Новосибирск, Станционнаая, 30в",
			dateContract: "20.05.23/666Б",
			finalCost: "20.05.24/ 585,89тыс.р",
			costPrice: "350,1 тыс.р",
			status: "Завершен",
		},
		{
			id: "#209",
			customer: "ООО “Каркаде”",
			address: "Новосибирск, Станционнаая, 30в",
			dateContract: "20.05.23/666Б",
			finalCost: "20.05.24/ 585,89тыс.р",
			costPrice: "350,1 тыс.р",
			status: "Завершен",
		},
		{
			id: "#210",
			customer: "ООО “Каркаде”",
			address: "Новосибирск, Станционнаая, 30в",
			dateContract: "20.05.23/666Б",
			finalCost: "20.05.24/ 585,89тыс.р",
			costPrice: "350,1 тыс.р",
			status: "Завершен",
		},
		{
			id: "#211",
			customer: "ООО “Каркаде”",
			address: "Новосибирск, Станционнаая, 30в",
			dateContract: "20.05.23/666Б",
			finalCost: "20.05.24/ 585,89тыс.р",
			costPrice: "350,1 тыс.р",
			status: "Завершен",
		},
		{
			id: "#212",
			customer: "ООО “Каркаде”",
			address: "Новосибирск, Станционнаая, 30в",
			dateContract: "20.05.23/666Б",
			finalCost: "20.05.24/ 585,89тыс.р",
			costPrice: "350,1 тыс.р",
			status: "Завершен",
		},
		{
			id: "#213",
			customer: "ООО “Каркаде”",
			address: "Новосибирск, Станционнаая, 30в",
			dateContract: "20.05.23/666Б",
			finalCost: "20.05.24/ 585,89тыс.р",
			costPrice: "350,1 тыс.р",
			status: "Завершен",
		},
		{
			id: "#214",
			customer: "ООО “Каркаде”",
			address: "Новосибирск, Станционнаая, 30в",
			dateContract: "20.05.23/666Б",
			finalCost: "20.05.24/ 585,89тыс.р",
			costPrice: "350,1 тыс.р",
			status: "Завершен",
		},
		{
			id: "#215",
			customer: "ООО “Каркаде”",
			address: "Новосибирск, Станционнаая, 30в",
			dateContract: "20.05.23/666Б",
			finalCost: "20.05.24/ 585,89тыс.р",
			costPrice: "350,1 тыс.р",
			status: "Завершен",
		},
		{
			id: "#216",
			customer: "ООО “Каркаде”",
			address: "Новосибирск, Станционнаая, 30в",
			dateContract: "20.05.23/666Б",
			finalCost: "20.05.24/ 585,89тыс.р",
			costPrice: "350,1 тыс.р",
			status: "Завершен",
		},
		{
			id: "#217",
			customer: "ООО “Каркаде”",
			address: "Новосибирск, Станционнаая, 30в",
			dateContract: "20.05.23/666Б",
			finalCost: "20.05.24/ 585,89тыс.р",
			costPrice: "350,1 тыс.р",
			status: "Завершен",
		},
		{
			id: "#218",
			customer: "ООО “Каркаде”",
			address: "Новосибирск, Станционнаая, 30в",
			dateContract: "20.05.23/666Б",
			finalCost: "20.05.24/ 585,89тыс.р",
			costPrice: "350,1 тыс.р",
			status: "Завершен",
		},
		{
			id: "#219",
			customer: "ООО “Каркаде”",
			address: "Новосибирск, Станционнаая, 30в",
			dateContract: "20.05.23/666Б",
			finalCost: "20.05.24/ 585,89тыс.р",
			costPrice: "350,1 тыс.р",
			status: "Завершен",
		},
		{
			id: "#220",
			customer: "ООО “Каркаде”",
			address: "Новосибирск, Станционнаая, 30в",
			dateContract: "20.05.23/666Б",
			finalCost: "20.05.24/ 585,89тыс.р",
			costPrice: "350,1 тыс.р",
			status: "Завершен",
		},
		{
			id: "#221",
			customer: "ООО “Каркаде”",
			address: "Новосибирск, Станционнаая, 30в",
			dateContract: "20.05.23/666Б",
			finalCost: "20.05.24/ 585,89тыс.р",
			costPrice: "350,1 тыс.р",
			status: "Завершен",
		},
		{
			id: "#222",
			customer: "ООО “Каркаде”",
			address: "Новосибирск, Станционнаая, 30в",
			dateContract: "20.05.23/666Б",
			finalCost: "20.05.24/ 585,89тыс.р",
			costPrice: "350,1 тыс.р",
			status: "Завершен",
		},
	],
};

export default dataTableData;

// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";

// OrderList-default page components
import IdCell from "layouts/ecommerce/orders-default/order-list/components/IdCell";
import DefaultCell from "layouts/ecommerce/orders-default/order-list/components/DefaultCell";
import StatusCell from "layouts/ecommerce/orders-default/order-list/components/StatusCell";
import CustomerCell from "layouts/ecommerce/orders-default/order-list/components/CustomerCell";

// Images
import team2 from "assets/images/team-2.jpg";

const dataTableData = {
	columns: [
		{
			Header: "id пользователя",
			accessor: "id",
			Cell: ({ value }) => <IdCell id={value} />,
		},
		{
			Header: "Дата добавления",
			accessor: "date",
		},
		{
			Header: "ID объекта",
			accessor: "objectId",
		},
		{
			Header: "Пользователь",
			accessor: "user",
			Cell: ({ value: [name, data] }) => (
				<CustomerCell
					image={data.image}
					color={data.color || "dark"}
					name={name}
				/>
			),
		},
		{
			Header: "Роль",
			accessor: "role",
			Cell: ({ value }) => (
				<StatusCell icon="settings" color="dark" status={value} />
			),
		},
		{
			Header: "Редактор",
			accessor: "editor",
		},
		{
			Header: "Статус",
			accessor: "status",
			Cell: ({ value }) => {
				if (value === "В сети") {
					return (
						<StatusCell icon="done" color="success" status="В сети" />
					);
				}
				if (value === "Не в сети") {
					return (
						<StatusCell icon="close" color="error" status="Не в сети" />
					);
				}
			},
		},
	],

	rows: [
		{
			id: "#10421",
			date: "1 Ноя, 10:20",
			objectId: "#201",
			user: ["Вадим Пушилин", { image: team2 }],
			role: "Редактор",
			editor: "Неизв",
			status: "В сети",
		},
		{
			id: "#10422",
			date: "1 Ноя, 10:47",
			objectId: "#202",
			user: ["Артем Полищук", { image: team2 }],
			role: "Оунер",
			editor: "Неизв",
			status: "В сети",
		},
		{
			id: "#10423",
			date: "1 Ноя, 15:30",
			objectId: "#203",
			user: ["Николай Мосеев", { image: team2 }],
			role: "Чтение",
			editor: "Неизв",
			status: "Не в сети",
		},
	],
};

export default dataTableData;

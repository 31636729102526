/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const timelineData = [
	{
		color: "success",
		icon: "notifications",
		title: "Добавление элемента",
		dateTime: "13 ДЕК. 17:58",
		description: "Пользователь Иван Петров",
		badges: ["design"],
	},
	{
		color: "error",
		icon: "inventory_2",
		title: "Редактирование элемента Скрытые работы",
		dateTime: "13 ДЕК. 17:51",
		description: "Пользователь Иван Петров",
		badges: ["order", "#1832412"],
	},
	{
		color: "info",
		icon: "shopping_cart",
		title: "Новый элемент Скрытые работы",
		dateTime: "13 ДЕК. 17:55",
		description: "Пользователь Иван Петров ",
		badges: ["server", "payments"],
	},
	{
		color: "warning",
		icon: "payment",
		title: "Редактирование элемента",
		dateTime: "",
		description: "Редактор Иван Петров",
		badges: ["card", "#4395133", "priority"],
	},
	{
		color: "primary",
		icon: "vpn_key",
		title: "Добавление разработчика",
		dateTime: "13 ДЕК. 16:55",
		description: "Добавлен разработчик Иван Петров",
		badges: ["development"],
	},
	{
		color: "success",
		icon: "inbox",
		title: "Смена собственника",
		dateTime: "13 ДЕК. 14:55",
		description: "Новый собственник Антон Березовский",
		badges: ["message"],
	},
	{
		color: "info",
		icon: "campaign",
		title: "Редактирование",
		dateTime: "13 ДЕК. 14:50",
		description: "Редактировал Семен Солдатов",
	},
	{
		color: "warning",
		icon: "archive",
		title: "Новый элемент",
		dateTime: "13 ДЕК. 14:50",
		description: "Создатель Иван Петров",
		badges: ["request", "priority"],
	},
];

export default timelineData;

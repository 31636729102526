// Material Dashboard 2 PRO React components
import MDTypography from "components/MDTypography";

// OrderList-default page components
import IdCell from "layouts/ecommerce/orders-default/order-list/components/IdCell";
import DefaultCell from "layouts/ecommerce/orders-default/order-list/components/DefaultCell";
import StatusCell from "layouts/ecommerce/orders-default/order-list/components/StatusCell";

const dataTableData = {
	columns: [
		{
			Header: "Работы",
			accessor: "works",
			Cell: ({ value }) => {
				if (value[1] === 1) {
					return <DefaultCell value={value[0]} />;
				} else {
					return (
						<MDTypography
							variant="body2"
							fontWeight="regular"
							color="text"
							fontSize="14px"
							ml="15px"
						>
							- {value[0]}
						</MDTypography>
					);
				}
			},
		},
		{
			Header: "Ед.изм",
			accessor: "units",
		},
		{
			Header: "Количество",
			accessor: "quantity",
		},
		{
			Header: "Цена, ₽",
			accessor: "price",
		},
		{
			Header: "Стоимость, ₽",
			accessor: "cost",
		},
		{
			Header: "Статус",
			accessor: "status",
			Cell: ({ value }) => (
				<StatusCell icon="settings" color="dark" status={value} />
			),
		},
	],

	rows: [
		{
			works: ["Проектирование", 1],
			units: "м2",
			quantity: "639,1",
			price: "522",
			cost: "333 610,2",
			status: "Согласов.",
		},
		{
			works: ["Протокол замера сопротивления", 0],
			units: "м2",
			quantity: "291,5",
			price: "180",
			cost: "52 470",
			status: "Выполнен",
		},
		{
			works: ["Рабочий проект АР, ЭО, ОВиК", 0],
			units: "м2",
			quantity: "244,3",
			price: "90",
			cost: "21 987",
			status: "Выполнен",
		},
		{
			works: ["Рабочий проект АУПТ, АПС, СОУЭ", 0],
			units: "м2",
			quantity: "919,2",
			price: "830",
			cost: "762 936",
			status: "Выполнен",
		},
		{
			works: ["Согласования", 1],
			units: "м2",
			quantity: "101,8",
			price: "491",
			cost: "49 983,8",
			status: "Выполнен",
		},
		{
			works: ["Протокол замера сопротивления", 0],
			units: "м2",
			quantity: "983",
			price: "371",
			cost: "364 693",
			status: "Выполнен",
		},
		{
			works: ["Страхование объекта", 0],
			units: "м2",
			quantity: "229,6",
			price: "943",
			cost: "216 512,8",
			status: "Выполнен",
		},
		{
			works: ["Строительство", 1],
			units: "м2",
			quantity: "54,3",
			price: "873",
			cost: "47 403,9",
			status: "Выполнен",
		},
		{
			works: ["Общестроительные работы", 1],
			units: "м2",
			quantity: "120,7",
			price: "769",
			cost: "92 818,3",
			status: "Выполнен",
		},
		{
			works: ["Протокол замера сопротивления", 0],
			units: "м2",
			quantity: "131,9",
			price: "948",
			cost: "125 041,2",
			status: "Выполнен",
		},
	],
};

export default dataTableData;

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";
import TextField from "@mui/material/TextField";

// react-routers components
import { Link } from "react-router-dom";

// OrderTable components
import DefaultCell from "layouts/templates/Order-table/components/DefaultCell";
import ElementMenuCell from "layouts/templates/Order-table/components/ElementMenuCell";
import UnitsMenuCell from "layouts/templates/Order-table/components/UnitsMenuCell";

const dataTableData = {
	columns: [
		{
			Header: "Работы",
			accessor: "works",
			Cell: ({ value }) => {
				if (value[1] === 1) return <DefaultCell value={value[0]} />;
				if (value[1] === 2)
					return (
						<MDTypography
							variant="body2"
							fontWeight="regular"
							color="text"
							fontSize="14px"
							ml="15px"
						>
							- {value[0]}
						</MDTypography>
					);
				return (
					<>
						<MDBox
							display="flex"
							alignItems="center"
							justifyContent="center"
							gap={2}
						>
							<TextField label="Введите название" variant="standard" />
							<MDButton
								variant="outlined"
								color="dark"
								size="small"
								component={Link}
								to="/application/new-element"
								iconOnly
								circular
							>
								<Icon sx={{ fontWeight: "bold" }}>add</Icon>
							</MDButton>
						</MDBox>
					</>
				);
			},
		},
		{
			Header: "Ед.изм",
			accessor: "units",
			Cell: ({ value }) => {
				if (value[1] === 1) {
					return (
						<MDTypography
							variant="body2"
							fontWeight="regular"
							color="text"
							fontSize="14px"
						>
							{value[0]}
						</MDTypography>
					);
				} else {
					return <UnitsMenuCell />;
				}
			},
		},
		{
			Header: "Количество",
			accessor: "quantity",
			Cell: ({ value }) => {
				if (value[1] === 1) {
					return (
						<MDTypography
							variant="body2"
							fontWeight="regular"
							color="text"
							fontSize="14px"
						>
							{value[0]}
						</MDTypography>
					);
				} else {
					return <TextField label="Количество" variant="standard" />;
				}
			},
		},
		{
			Header: "Цена, ₽",
			accessor: "price",
			Cell: ({ value }) => {
				if (value[1] === 1) {
					return (
						<MDTypography
							variant="body2"
							fontWeight="regular"
							color="text"
							fontSize="14px"
						>
							{value[0]}
						</MDTypography>
					);
				} else {
					return <TextField label="Цена" variant="standard" />;
				}
			},
		},
		{
			Header: "Стоимость, ₽",
			accessor: "cost",
			Cell: ({ value }) => {
				if (value[1] === 1) {
					return (
						<MDTypography
							variant="body2"
							fontWeight="regular"
							color="text"
							fontSize="14px"
						>
							{value[0]}
						</MDTypography>
					);
				} else {
					return <TextField label="Стоимость" variant="standard" />;
				}
			},
		},
		{
			Header: "Статус",
			accessor: "status",
			Cell: ({ value }) => (
				<ElementMenuCell icon="settings" color="dark" status={value} />
			),
		},
	],
	rows: [],
};

export default dataTableData;
